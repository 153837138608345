const host = "http://87.249.49.97:71";

const paramsImg = {
  headers: {
    // 'Authorization': Token,
    "Content-Type": "multipart/form-data",
  },
};

export { host, paramsImg };
