<template>
    <div class="not-found__container">
        <h1 class="not-found-message">Not Found 404!</h1>
    </div>
</template>

<script>

</script>

<style scoped>
.not-found__container {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}
.not-found-message {
    color: var(--text-primary);
    font-size: 6em;
    font-weight: bolder;
}
</style>